import Splide from '@splidejs/splide';
import SimpleLightbox from 'simple-lightbox';
import AOS from 'aos';

import MobileMenu from './mobileMenu';
import Translator from './translation';

const App = {
    gallery: null,
    
    initSlider() {
        new Splide('.splide', {
            rewind: true,
            trimSpace: false,
            autoplay: false,
            interval: 3500
        }).mount();
    },

    initGallery() {
        this.gallery = new SimpleLightbox({
            elements: '.image-gallery .splide__slide a'
        })
    },

    initAnimations() {
        AOS.init({
            duration: 600,
            once: true,
            offset: 0
        });
    },

    initVideo() {
        const Video = document.querySelector('.video');
        if (!Video) return;

        Video.play();
        Video.loop = true;
    },

    initLinks() {
        const Links = document.querySelectorAll('.navigation .link');

        const goToSection = sectionId => {
            const Section = document.getElementById(sectionId);

            if (window.innerWidth <= 765) {
                MobileMenu.close();
            }

            window.scrollTo({
                top: Section.offsetTop - 100,
                left: 0,
                behavior: 'smooth'
            });
        };

        Links.forEach(Link => {
            Link.addEventListener('click', event => {
                event.preventDefault();

                const id = event.target.href.split('#')[1];
                goToSection(id);
            });
        });
    },

    refreshGalleryCaptions () {
        const images = document.querySelectorAll('.splide__slide .item');
        const newCaptions = [];
        
        images.forEach(Image => newCaptions.push(Image.title));

        this.gallery.captions = newCaptions;
    },

    setActiveLangButton (Button) {
        document.querySelector('[data-trigger="switchLang"].button--active').classList.remove('button--active');
        Button.classList.add('button--active');
    },

    setLang (lang) {
        document.documentElement.lang = lang;

        if (lang !== 'pl') {
            window.location.hash = '#' + lang;
        } else {
            window.history.replaceState({}, document.title, '/');
        }
    },

    detectPageLang () {
        if (window.location.hash === '#en') {
            this.setLang('en');

            this.setActiveLangButton(
                document.querySelector('[data-lang="en"]')
            );

            Translator.translate();
        }
    },

    bindTranslationNavigation () {
        const Buttons = document.querySelectorAll('[data-trigger="switchLang"]');
        if (!Buttons) return;

        Buttons.forEach(Button => {
            Button.addEventListener('click', event => {
                event.preventDefault();

                if (Button.classList.contains('button--active')) {
                    return;
                }

                this.setActiveLangButton(Button);

                const lang = Button.dataset.lang;
                this.setLang(lang);

                Translator.translate();
                
                this.refreshGalleryCaptions();
            });
        });
    },
    

    initTranslation () {
        this.detectPageLang();
        this.bindTranslationNavigation();
    },

    initFooterDate () {
        const el = document.querySelector('[data-target="footerDate"]');

        el.innerText = new Date().getFullYear();
    },

    init() {
        this.initSlider();
        this.initGallery();
        this.initAnimations();
        this.initVideo();
        this.initLinks();
        this.initTranslation();
        this.initFooterDate();
    
        MobileMenu.init();
    }
};

document.addEventListener('DOMContentLoaded', () => App.init());