const Langs = {
  "page.title": { en: "Ophthalmology department - DMedSc Anna Mańkowska" },
  "page.description": {
    en: "In the office there are consultations and qualifications for the following surgeries: cataract surgeries, anti-glare surgeries, retina and vitreous body surgeries (vitrectomy), laser therapy in diabetes and glaucoma patients. On-site diagnosis of glaucoma and retinal diseases is also carried out.",
  },
  "page.meta.keywords": {
    en: "cataract surgeries, anti-glare operations, retina and vitreous body operations (vitrectomy), laser therapy",
  },
  "header.registration": { en: "Registration" },
  "header.companyTypeText1": { en: "Ophthalmology" },
  "header.companyTypeText2": { en: "department" },
  "header.contact": { en: "Contact" },
  "header.directions": { en: "Route" },
  "header.gallery": { en: "Gallery" },
  "hero.dr": { en: "DMedSc" },
  "contact.phoneRegistration": { en: "Telephone registration" },
  "contact.hours": { en: "Admission hours" },
  "contact.location": { en: "Location" },
  "contact.groundFloor": { en: "Ground floor" },
  "contact.route": { en: "Set the route" },
  "contact.street": { en: "Cicha Street 10 / 1U" },
  "gallery.img1": { en: "The building of the Ophthalmology office" },
  "gallery.img2": { en: "Information board" },
  "gallery.img3": {
    en: "Reception desk - the entrance to the office is on the left",
  },
  "gallery.img4": { en: "Entrance to the Ophthalmology Office" },
  "gallery.img5": {
    en: "The interior of the waiting room of the Ophthalmology Office",
  },
  "gallery.img6": { en: "The appearance of the waiting room" },
  "gallery.img7": { en: "The inscription on the wall" },
  "info.text": {
    en: 'The Ophthalmology Clinic is located at 10/1u Cicha Street. Please remember that during the working hours of stores and offices, parking in front of the office is difficult. In case of any problems, please use the parking lots located on the map about 300 meters from the Clinic (<a href="https://goo.gl/maps/X9hYv5GVxc4DZG728" target="_blank">map link</a>).',
  },
  "info.link": { end: "Google Maps link" },
  "video.title": { en: "Directions to the office" },
  "video.nojs": {
    en: 'Your browser does not support videos using HTML5. Here you will find <a href="/videos/road.mp4">link to the movie',
  },
  "video.description": {
    en: "The above video shows the entrance instructions to the ophthalmic surgery",
  },
  "payment.title": { en: "We accept payment methods:" },
};

export default Langs;
