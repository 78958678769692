const MobileMenu = {
    Burger: null,
    Container: null,
    isOpened: false,

    close () {
        this.Burger.classList.remove('button--active');
        this.Container.classList.remove('navigation--opened');
    },

    bindClickOutsideEvent () {
        const hideMenuController = event => {
            const triggerAttribute = event.target.getAttribute('data-trigger')
            const isBurgerButton = triggerAttribute && triggerAttribute.includes('toggleMenu');

            if (isBurgerButton) return;

            const elementInContainer = this.Container.contains(event.target);

            if (!elementInContainer) {
                this.close();
                this.isOpened = !this.isOpened;
            }
        };

        document.addEventListener('click', hideMenuController);
    },


    bindBurgerEvent () {
        this.Burger.addEventListener('click', () => {
            this.Burger.classList.toggle('button--active');
            this.Container.classList.toggle('navigation--opened');
            this.isOpened = !this.isOpened;
        });
    },

    init () {
        this.Burger = document.querySelector('[data-trigger="toggleMenu"]');
        this.Container = document.querySelector('[data-target="navigaion"]');

        this.bindBurgerEvent();
        this.bindClickOutsideEvent();
    }
};

export default MobileMenu;