import langs from './lang';

const Translator = {
    saveCurrentLang(translationPath, currentText) {
        langs[translationPath].pl = currentText;
    },

    translate() {
        const currentLang = document.documentElement.lang;
        const ElementsToTranslate = document.querySelectorAll('[data-translate]');

        const changeText = Element => {
            let destination;

            if (Element.tagName === 'META') {
                destination = 'content';
            } else if (Element.tagName === 'A' && Element.dataset.gallery) {
                destination = 'title';
            } else if (Element.tagName === 'IMG') {
                destination = 'alt';
            } else {
                destination = 'innerHTML';
            }

            Element[destination] = langs[Element.dataset.translate][currentLang];
        };

        let content;
        const translateWithSave = Element => {
            if (Element.tagName === 'META') {
                content = Element.content;
            } else if (Element.tagName === 'A' && Element.dataset.gallery) {
                content = Element.title;
            } else if (Element.tagName === 'IMG') {
                content = Element.alt;
            } else {
                content = Element.innerHTML
            }

            this.saveCurrentLang(
                Element.dataset.translate,
                content
            );

            changeText(Element);
        };

        if (currentLang === 'pl') {
            ElementsToTranslate.forEach(changeText);
        } else {
            ElementsToTranslate.forEach(translateWithSave);
        }
    }
};

export default Translator;